"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const bmi_percentile_calculator_1 = require("@trifoia/bmi-percentile-calculator");
class BmicalculatorCtrl {
    constructor($scope, $filter) {
        // this.ageRange = '2-19'
        // this.age = {
        //     years: 4,
        //     month: 6
        // }
        // this.height.sm = 116
        // this.weight.kg = 22
        this.$scope = $scope;
        this.$filter = $filter;
        this.unit = JSON.parse(localStorage.getItem('options') || '{"unit": "metric"}').unit;
        this.ageRange = JSON.parse(localStorage.getItem('options') || '{"ageRange": "20+"}').ageRange;
        this.height = {
            'sm': undefined,
            'feet': undefined,
            'inch': undefined,
        };
        this.weight = {
            'kg': undefined,
            'pound': undefined
        };
        this.gender = 'male';
        this.age = {
            'years': undefined,
            'month': undefined
        };
        this.$scope.$watch(() => {
            return [this.unit, this.ageRange];
        }, (value) => {
            localStorage.setItem('options', JSON.stringify({
                unit: value[0],
                ageRange: value[1],
            }));
        }, true);
    }
    setUnit(unit, ageRange) {
        this.unit = unit;
        this.ageRange = ageRange || '20+';
        if (this.unit == 'metric') {
            this.height = {
                'sm': undefined
            };
            this.weight = {
                'kg': undefined
            };
        }
        else if (this.unit == 'imperial') {
            this.height = {
                'feet': undefined,
                'inch': undefined
            };
            this.weight = {
                'pound': undefined
            };
        }
        if (this.bmiForm)
            this.clear(this.bmiForm);
    }
    $onInit() {
    }
    submitForm($form) {
        if ($form.$valid) {
            let months;
            if ((this.ageRange == '2-19') && this.age.years != undefined) {
                months = this.age.years * 12 + (this.age.month || 0);
            }
            else if (this.ageRange == '20+') {
                months = 30 * 12;
            }
            else {
                throw "Unknown years";
            }
            if (this.unit == 'metric') {
                if ((this.weight.kg != undefined) &&
                    (this.height.sm != undefined) &&
                    (months != undefined)) {
                    this.bmi = (0, bmi_percentile_calculator_1.metric)(this.weight.kg, this.height.sm / 100, this.gender == 'male' ? 'm' : 'f', months);
                }
            }
            else if (this.unit == 'imperial') {
                if ((this.weight.pound != undefined) &&
                    (this.height.feet != undefined) &&
                    (months != undefined)) {
                    this.bmi = (0, bmi_percentile_calculator_1.english)(this.weight.pound, this.height.feet * 12 + (this.height.inch || 0), this.gender == 'male' ? 'm' : 'f', months);
                }
            }
            else {
                this.bmi = undefined;
            }
            if (this.bmi != undefined) {
                if (this.ageRange == '20+') {
                    if (this.bmi.bmi < 16.0) {
                        this.bmi.current = 'severely-underweight';
                        this.bmi.styleLeft = `${(this.bmi.bmi - 2.52) * 100 / (16 - 2.52)}%`;
                        this.bmi.title = this.$filter('translate')('The WHO regards a BMI of less than 16 as severely underweight and may indicate malnutrition, an eating disorder, or other health problems.');
                    }
                    else if ((this.bmi.bmi >= 16.0) && (this.bmi.bmi <= 18.49)) {
                        this.bmi.current = 'underweight';
                        this.bmi.styleLeft = `${(this.bmi.bmi - 16) * 100 / (18.49 - 16)}%`;
                        this.bmi.title = this.$filter('translate')('The WHO regards a BMI of less than 18.5 as underweight and may indicate malnutrition, an eating disorder, or other health problems.');
                    }
                    else if ((this.bmi.bmi >= 18.5) && (this.bmi.bmi <= 24.99)) {
                        this.bmi.current = 'normal';
                        this.bmi.styleLeft = `${(this.bmi.bmi - 18.5) * 100 / (24.99 - 18.5)}%`;
                        this.bmi.title = this.$filter('translate')('A BMI between 18.5 and 25 is considered normal and healthy.');
                    }
                    else if ((this.bmi.bmi >= 25.0) && (this.bmi.bmi <= 29.99)) {
                        this.bmi.current = 'overweight';
                        this.bmi.styleLeft = `${(this.bmi.bmi - 25) * 100 / (29.99 - 25)}%`;
                        this.bmi.title = this.$filter('translate')('People who fall into this category may be at risk of developing obesity. This was earlier classified as overweight.');
                    }
                    else if ((this.bmi.bmi >= 30.0) && (this.bmi.bmi <= 34.99)) {
                        this.bmi.current = 'obese-i';
                        this.bmi.styleLeft = `${(this.bmi.bmi - 30) * 100 / (34.99 - 30)}%`;
                        this.bmi.title = this.$filter('translate')('People who have BMI equal or over 30 may have obesity, which is defined as an abnormal or excessive accumulation of fat that may harm health.');
                    }
                    else if ((this.bmi.bmi >= 35.0) && (this.bmi.bmi <= 39.99)) {
                        this.bmi.current = 'obese-ii';
                        this.bmi.styleLeft = `${(this.bmi.bmi - 35) * 100 / (39.99 - 35)}%`;
                        this.bmi.title = this.$filter('translate')('People who have BMI equal or over 30 may have obesity, which is defined as an abnormal or excessive accumulation of fat that may harm health.');
                    }
                    else if (this.bmi.bmi >= 40.0) {
                        this.bmi.current = 'obese-iii';
                        this.bmi.styleLeft = `${(this.bmi.bmi - 40) * 100 / (755.56 - 40)}%`;
                        this.bmi.title = this.$filter('translate')('People who have BMI equal or over 30 may have obesity, which is defined as an abnormal or excessive accumulation of fat that may harm health.');
                    }
                }
                else if (this.ageRange == '2-19') {
                    if (this.bmi.percentile < 4.99) {
                        this.bmi.styleLeft = `${(this.bmi.percentile - 0) * 100 / (4.99)}%`;
                        this.bmi.current = 'underweight';
                        this.bmi.title = this.$filter('translate')('The WHO regards a BMI of less than 18.5 as underweight and may indicate malnutrition, an eating disorder, or other health problems.');
                    }
                    else if ((this.bmi.percentile >= 5) && (this.bmi.percentile < 84.99)) {
                        this.bmi.styleLeft = `${(this.bmi.percentile - 5) * 100 / (84.99 - 5)}%`;
                        this.bmi.current = 'normal';
                        this.bmi.title = this.$filter('translate')('A BMI between 18.5 and 25 is considered normal and healthy.');
                    }
                    else if ((this.bmi.percentile >= 85) && (this.bmi.percentile < 94.99)) {
                        this.bmi.styleLeft = `${(this.bmi.percentile - 85) * 100 / (94.99 - 85)}%`;
                        this.bmi.current = 'overweight';
                        this.bmi.title = this.$filter('translate')('People who fall into this category may be at risk of developing obesity. This was earlier classified as overweight.');
                    }
                    else if (this.bmi.percentile >= 95) {
                        this.bmi.styleLeft = `${(this.bmi.percentile - 95) * 100 / (100 - 95)}%`;
                        this.bmi.current = 'obese-iii';
                        this.bmi.title = this.$filter('translate')('People who have BMI equal or over 30 may have obesity, which is defined as an abnormal or excessive accumulation of fat that may harm health.');
                    }
                }
            }
        }
    }
    clear($form) {
        $form.$setPristine();
        $form.$setUntouched();
        this.bmi = undefined;
        this.height = {};
        this.weight = {};
        this.age = {};
    }
}
BmicalculatorCtrl.$inject = ['$scope', '$filter'];
const appModule = ng.module('app');
appModule.directive('rangeArrow', [() => {
        return {
            link: ($scope, $element) => {
                //@ts-ignore
                const arrow = $element[0].getElementsByClassName('arrow-down');
                console.log($element.width);
            }
        };
    }]);
appModule.component('gameBmicalculator', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: BmicalculatorCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.config(['WsServiceProvider', (WsServiceProvider) => {
        WsServiceProvider.setPrefix('bmicalculator/');
    }]);
